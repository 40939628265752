import React from "react"
import InputGroup from "react-bootstrap/InputGroup"
import { StyledFormControl } from "./contactForm/StyledContactForm"

const SearchBar = ({ keyword, setKeyword }) => {
  return (
    <InputGroup className="mb-3" id="search-bar">
      <StyledFormControl
        placeholder="Suche Event Titel"
        value={keyword}
        aria-label="Suche Event Titel"
        onChange={e => setKeyword(e.target.value)}
        aria-describedby="search-bar"
      />
    </InputGroup>
  )
}

export default SearchBar
