import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"
import { StyledAccordion } from "./common/StyledAccordion"
import StyledLinkButton from "./common/StyledLinkButton"

function UebersichtAccorion({ events }) {
  // create arr with the data grouped by day
  let days = []

  for (let index = 0; index < events.length; index++) {
    const element = events[index]
    days.push(element.day)
  }

  // remove duplicates
  const uniqueDays = [...new Set(days)]

  return (
    <StyledAccordion defaultActiveKey="0" className="mt-5">
      {uniqueDays.map((day, i) => {
        return (
          <Card key={i} className="outer">
            <Card.Header>
              <Accordion.Toggle
                as={Card.Header}
                variant="link"
                eventKey={`0 + ${i}`}
              >
                {day}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={`0 + ${i}`}>
              <Card.Body>
                <Accordion defaultActiveKey="0">
                  {events.map((event, i) => {
                    if (event.day === day) {
                      return (
                        <Card key={i} className="inner">
                          <Card.Header>
                            <Accordion.Toggle
                              as={Card.Header}
                              variant="link"
                              eventKey={`0 + ${i}`}
                            >
                              {event.title}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={`0 + ${i}`}>
                            <Card.Body>
                              <ListGroup
                                style={{
                                  background: "transparent",
                                  borderRadius: "1rem",
                                }}
                              >
                                <ListGroup.Item
                                  style={{ background: "transparent" }}
                                >
                                  {event.event_type}, {event.startTime} -{" "}
                                  {event.endTime}
                                </ListGroup.Item>
                                <ListGroup.Item
                                  style={{ background: "transparent" }}
                                >
                                  {event.event_place}
                                </ListGroup.Item>
                              </ListGroup>
                              <StyledLinkButton
                                to={`/${event.id}`}
                                style={{ marginTop: "1.2rem" }}
                              >
                                ...mehr
                              </StyledLinkButton>
                              {/* no english translations at the moment */}
                              {/* <StyledLinkButton to={`../${event.id}`} style={{marginTop: "1.2rem"}}>...mehr</StyledLinkButton> */}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )
                    } else {
                      return null
                    }
                  })}
                </Accordion>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      })}
    </StyledAccordion>
  )
}

export default UebersichtAccorion
